import React from "react"

import Footer from "./footer"
import Header from "./header"
import { rhythm } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { children, location, title } = this.props
    const style = {
      marginLeft: `auto`,
      marginRight: `auto`,
      padding: `0 ${rhythm(0.25)}`,
      maxWidth: rhythm(24),
    }
    return (
      <div>
        <Header location={location} title={title} />
        <main style={style}>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
