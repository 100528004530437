import React from "react"

import { commitSha } from "../config"
import { rhythm } from "../utils/typography"

class Footer extends React.Component {
  render() {
    const style = {
      margin: `auto`,
      padding: `${rhythm(0.5)} 0`,
      textAlign: "center",
    }
    return (
      <footer style={style}>
        <hr />
        <p>
          <a href="#top">To the top</a>
        </p>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
        {` `}
        <div>
          <code>{`${
            commitSha && commitSha.substring
              ? commitSha.substring(0, 8)
              : "d34db33f"
          }`}</code>
        </div>
        <div>
          <a href="https://travels.vikfand.com">My travel blog</a>
        </div>
        <div>
          <a href="https://www.vikfand.com">My promotional page</a>
        </div>
      </footer>
    )
  }
}

export default Footer
