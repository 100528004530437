import React from "react"

import { rhythm, scale } from "../utils/typography"
import { getLiveBio } from "../api"

import { Link } from "gatsby"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { liveBio: {} }
  }
  componentDidMount() {
    getLiveBio()
      .then(liveBio => {
        this.setState({ liveBio })
      })
      .catch(console.error)
  }
  render() {
    const { location, title } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    if (location.pathname === rootPath) {
      return (
        <header style={{ textAlign: "center" }}>
          <p style={{ backgroundColor: "lightgray" }}>
            <small>{`Location: ${this.state.liveBio.location}`}</small>
          </p>
          <h1
            style={{
              ...scale(1.0),
              marginBottom: rhythm(1.0),
              marginTop: 0,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
        </header>
      )
    } else {
      return (
        <header style={{ textAlign: "center" }}>
          <h3
            style={{
              fontFamily: `Montserrat, sans-serif`,
              marginTop: rhythm(0.2),
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h3>
        </header>
      )
    }
  }
}

export default Header
