const liveBioUrl =
  "https://spreadsheets.google.com/feeds/list/1Jpx0DIV2UoSnYhDyql6KkKV9XOs10_S7UYZUKSadq1s/od6/public/values?alt=json"
export const getSpreadsheet = async (url) => {
  return fetchJson(url)
}

const fetchJson = async (url) => {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`Could not fetch spreadsheet, status=${response.status}`)
  }
  return response.json()
}

export const getLiveBio = async () => {
  const spreadsheet = await getSpreadsheet(liveBioUrl)
  return spreadsheet.feed.entry.reduce((acc, { title, gsx$value }) => {
    return { ...acc, [title.$t]: gsx$value.$t }
  }, {})
}
